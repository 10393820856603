






































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  name: 'Maps',
})
export default class Maps extends Vue {
  @Prop({ type: Function }) confirm!: Function;
  public zoom = 12;
  public center = [121.59996, 31.197646];
  public address = '';
  public events: { click?: Function; init?: Function } = {};
  public lng = 0;
  public lat = 0;
  public loaded = false;
  public mapDialog = false;
  public wait = false;
  public plugin: any = [];
  public searchOption = {
    city: '',
    citylimit: false,
  };
  public created() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.plugin = [
      {
        pName: 'Geolocation',
        events: {
          init(o: {
            getCurrentPosition: (
              arg0: (
                status: any,
                result: {
                  position: { lng: number; lat: number };
                  formattedAddress: string;
                }
              ) => void
            ) => void;
          }) {
            console.log('asda :>> ', o);
            // o 是高德地图定位插件实例
            o.getCurrentPosition(
              (
                status: any,
                result: {
                  position: { lng: number; lat: number };
                  formattedAddress: string;
                }
              ) => {
                console.log('result :>> ', result);
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                  self.address = result.formattedAddress;
                  self.loaded = true;
                  self.$nextTick();
                }
              }
            );
          },
        },
      },
    ];
    this.events = {
      click: (e: any) => {
        console.log('e :>> ', e);
        const { lng, lat } = e.lnglat;
        self.lng = lng;
        self.lat = lat;
        const lngLat = [lng, lat];
        this.center = lngLat;
        // 这里通过高德 SDK 完成。
        const geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: 'all',
        });
        geocoder.getAddress(lngLat, function (
          status: string,
          result: { info: string; regeocode: { formattedAddress: string } }
        ) {
          console.log('result :>> ', result);
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.regeocode) {
              self.address = result.regeocode.formattedAddress;
              self.$nextTick();
            }
          }
        });
      },
    };
    this.wait = true;
  }
  public onSearchResult(pois: string | any[]) {
    console.log('pois :>> ', pois);
    if (pois.length > 0) {
      const center = {
        lng: pois[0].lng,
        lat: pois[0].lat,
      };
      this.lng = center.lng;
      this.lat = center.lat;
      this.address = pois[0].address;
      this.center = [center.lng, center.lat];
    }
  }
  public mapConfirm() {
    this.$emit('confirm', this.lng, this.lat, this.address);
    this.mapDialog = false;
  }
}
