







































































































































































































































import { Component, Vue } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  getCalendar,
  updateCalender,
} from '@/serve/medicalAppointment/institutionManage.ts';
import moment from 'moment';
interface Date {
  startTime: string;
  week: string;
  isRest: boolean;
  day: number;
  isHoliday: boolean;
  holidayName: string;
  people: number;
}
interface Calendar<T> {
  tenantId: T;
  year: string;
}
@Component({
  name: 'Arrange',
})
export default class Arrange extends Vue {
  private tenant = {};
  private monData: string[] = [];

  private num = 0;
  private visible = false;
  private visible2 = false;
  private visible3 = false;
  private nowYearMon = '';
  private nowDate: {}[] = [];
  private mapData = new Map();
  private checkData = new Map();
  private week = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
  private yearData: string[] = [
    moment().add(0, 'years').format('YYYY'),
    moment().add(1, 'years').format('YYYY'),
  ];
  private weekData = {
    星期一: (100 / 7) * 0,
    星期二: (100 / 7) * 1,
    星期三: (100 / 7) * 2,
    星期四: (100 / 7) * 3,
    星期五: (100 / 7) * 4,
    星期六: (100 / 7) * 5,
    星期日: (100 / 7) * 6,
  };
  private checkDate = '';
  private today = '';
  private year = '';
  private dateData: { [prototype: string]: Date[] } = {};
  /**配置初始数据 */
  private async initDeploy(data: Calendar<number>, nowYearMon: string) {
    const res = await getCalendar(data);
    this.dateData = res.data;
    this.monData = Object.keys(this.dateData);
    const keys = Object.keys(this.dateData);
    let key: string | undefined;
    while ((key = keys.shift())) {
      this.dateData[key].forEach((ele, index) => {
        if (!this.mapData.get(ele.startTime)) {
          this.mapData.set(ele.startTime, key + '-' + index);
        }
      });
    }
    this.nowYearMon = nowYearMon;
  }
  /**父组件触发该初始化方法 */
  public async getArrange(row: { id: number }) {
    this.year = moment().format('YYYY');
    this.tenant = row;
    this.today = moment().format('YYYY-MM-DD');
    const data = {
      tenantId: row.id,
      year: this.year,
    };
    this.initDeploy(data, moment().format('YYYY年MM月'));
  }
  /**更改年 */
  private changeYears(year: string) {
    if (this.checkData.size > 0) {
      this.saveData();
    }
    this.year = year;
    const data = {
      tenantId: (this.tenant as { id: number }).id,
      year,
    };
    this.initDeploy(data, year + '年01月');
  }
  /**左右切换按钮选择日期 */
  private leftRightBtn(type: 'left' | 'right') {
    const maxLength = this.monData.length;
    let index: number = this.monData.indexOf(this.nowYearMon);
    if (type === 'left') {
      index !== 0 && index--;
    } else {
      index < maxLength - 1 && index++;
    }
    this.nowYearMon = this.monData[index];
  }
  /**选择日期 */
  private checkFunc(row: any) {
    if (!row.isWorkDay) {
      return;
    }
    this.checkDate = row.startTime;
  }
  /**周为单位的统一处理 */
  private weekDeploy(nums: 7 | 14) {
    const data = [];
    let days = nums;
    while (days-- > 0) {
      const day = moment().add(days, 'days').format('YYYY-MM-DD');
      const mapVal = this.mapData.get(day);
      const [key, index] = mapVal.split('-');
      this.dateData[key][index].people = this.num;
      data.push(this.dateData[key][index]);
    }
    // const row = this.tenant as { id: number };
    this.saveData(null, data);
  }
  /**本年为单位的统一处理 */
  private async YearDeploy() {
    this.year = moment().format('YYYY');
    const data = {
      tenantId: (this.tenant as { id: number }).id,
      year: this.year,
    };
    const res = await getCalendar(data);
    this.dateData = res.data;
    this.monData = Object.keys(this.dateData);
    const keys = Object.keys(this.dateData);
    let key: string | undefined;
    while ((key = keys.shift())) {
      this.dateData[key].forEach((ele, index) => {
        if (!this.mapData.get(ele.startTime)) {
          this.mapData.set(ele.startTime, key + '-' + index);
        }
      });
    }
    this.nowYearMon = moment().format('YYYY年MM月');
    const list: {}[] = [];
    Object.keys(this.dateData).forEach((ele) => {
      this.dateData[ele].map((item) => {
        list.push(item);
        return (item.people = this.num);
      });
    });
    this.saveData(null, list);
    /*
    let i = 0;
    while (i <= 13) {
      this.saveData(null, list.splice(0, 30));
      i++;
    }*/
  }
  /**默认从今天开始根据选择的条件进行赋值操作 */
  private changeNum(type: '下周' | '下下周' | '本年') {
    const isEve =
      moment().format('YYYY年') !== moment().add(7, 'days').format('YYYY年');
    if ((type === '下周' || type === '下下周') && isEve) {
      this.$message({
        message: '涉及跨年操作建议手动保存修改',
        type: 'warning',
      });
      return;
    }
    switch (type) {
      case '下周':
        this.weekDeploy(7);
        break;
      case '下下周':
        this.weekDeploy(14);
        break;
      case '本年':
        this.YearDeploy();
        break;
      default:
        break;
    }
    this.visible3 = false;
    this.visible2 = false;
    this.visible = false;
  }
  /**放置被修改过,被点击过的排班数据，格式化储存 */
  private setCheck(item: Date) {
    this.checkData.set(item.startTime, item);
  }
  /**将格式化好的数据提交 */
  private async saveData(event?: any, list = [...this.checkData.values()]) {
    const data = {
      tenantId: (this.tenant as { id: number }).id,
      rosterList: list,
    };
    const res = await updateCalender(data);
    if (res.success) {
    console.log(res.success)
    this.$message({
        type: 'success',
        message: res.msg,
    });
      this.checkData.clear();
    }
  }
  /**点击返回按钮 */
  public back() {
    this.$emit('arrangeBack');
  }
}
