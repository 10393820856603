












































































































































import { Component, Vue } from 'vue-property-decorator';
import AddAndEdit from './addAndEdit.vue';
import Arrange from './arrange.vue';
import {
  getTenantData,
  deleteTenant,
} from '@/serve/medicalAppointment/institutionManage';
import areaDetail from '@/utils/areaDetail';
// import { Row } from 'element-ui';
@Component({
  name: 'InstitutionManage',
  components: { AddAndEdit, Arrange },
  filters: {
    changeStatus(num: 0 | 9): string {
      const type = {
        0: '正常',
        9: '冻结',
      };
      return type[num];
    },
  },
})
export default class InstitutionManage extends Vue {
  public formLabelWidth = '120px';
  public form = {
    nature: '',
    name: '',
    grade: '',
  };
  public page = {
    current: 1,
    size: 10,
  };
  public tenantData = [];
  public total = 0;
  public IsIndex = true;
  public IsArrange = false;
  public created() {
    this.getTenantData();
    // this.getAllPermissions();
  }
  //获取租户表数据
  public async getTenantData(form?: any): Promise<void> {
    const res = await getTenantData({ ...this.page, ...form });
    const arr = res.data.records;
    arr.forEach(
      (ele: {
        realAddress: string;
        province: string;
        city: string;
        district: string;
      }) => {
        ele['realAddress'] =
          (areaDetail as any).provinceList[ele.province] +
          (areaDetail as any).cityList[ele.city] +
          (areaDetail as any).countyList[ele.district];
      }
    );
    this.tenantData = arr
    this.total = res.data.total;
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getTenantData({ ...this.page, ...this.form });
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getTenantData({ ...this.page, ...this.form });
  }
  // 搜索
  public search() {
    const value = { ...this.page, ...this.form };
    this.getTenantData(this.$changNull(value));
    // this.tenantData = res.data.records;
    // this.total = res.data.total;
  }
  //给表单页面的返回按钮
  public back(refesh: boolean) {
    this.IsIndex = true;
    if (refesh) {
      this.getTenantData();
    }
  }
  public arrangeBack() {
    this.IsIndex = true;
    this.IsArrange = false;
  }
  //编辑租户
  public editRow(idx: number, row: { id: string }): void {
    this.IsIndex = false;
    this.$nextTick(() => {
      (this.$refs.AddAndEdit as AddAndEdit).getTenant(row);
    });
  }
  //多选删除
  public handleSelectionChange() {
    const list = (this.$refs.table as any).selection;
    this.deleteRow(list);
  }
  //删除
  public deleteRow(list: { id: number }[]): void {
    (this as any).$messageBox
      .confirm('您确定是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const ids: number[] = [];
        list.forEach((ele) => {
          ids.push(ele.id);
        });
        const res = await deleteTenant(ids);
        if (res.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          });
          this.getTenantData();
        }
        // else {
        //   this.$message.error('删除失败' + res.msg);
        // }
      });
  }
  // 重置功能
  public resetForm() {
    this.form = {
      nature: '',
      name: '',
      grade: '',
    };
    this.page = {
      current: 1,
      size: 10,
    };
    this.getTenantData();
  }
  //排班
  public arrange(row: { id: number }) {
    this.IsIndex = false;
    this.IsArrange = true;
    this.$nextTick(() => {
      (this.$refs.Arrange as Arrange).getArrange(row);
    });
  }
}
