






















































































































































































































































































import { ElForm } from "element-ui/types/form";
import { Component, Vue, Prop } from "vue-property-decorator";
import Maps from "./map.vue";
import area from "@/utils/area.ts";
import {
  addUpdateTenant,
  getNodeData,
  uploadImg,
} from "@/serve/medicalAppointment/institutionManage.ts";
interface FormData {
  topic: string;
  standardType: string;
  sort: number | string | undefined;
  topicType: string;
  more: string;
  [index: string]: any;
}
@Component({
  name: "AddAndEdit",
  components: { Maps },
})
export default class AddAndEdit extends Vue {
  @Prop({ type: Function }) back!: Function;
  public rules = {
    name: [{ required: true, message: "请输入机构名称", trigger: "blur" }],
    code: [{ required: true, message: "请输入机构编号", trigger: "blur" }],
    img: [{ required: true, message: "请选择封面", trigger: "blur" }],
    status: [{ required: true, message: "请选择机构状态", trigger: "blur" }],
    nature: [{ required: true, message: "请选择机构性质", trigger: "blur" }],
    grade: [{ required: true, message: "请选择机构等级", trigger: "blur" }],
    province: [{ required: true, message: "请选择省份", trigger: "blur" }],
    city: [{ required: true, message: "请选择城市", trigger: "blur" }],
    district: [{ required: true, message: "请选择区/街道", trigger: "blur" }],
    address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
    "jkglTenantDetail.tenantDetailLat": [
      { required: true, message: "请输入机构经度", trigger: "blur" },
    ],
    "jkglTenantDetail.tenantDetailLong": [
      { required: true, message: "请输入机构维度", trigger: "blur" },
    ],
    "jkglTenantDetail.tenantDetailJobTime": [
      {
        required: true,
        message: "输入时间范围例如:(周一至周五,8点至20点)",
        trigger: "blur",
      },
    ],
    "jkglTenantDetail.tenantDetailTelephone": [
      {
        required: true,
        validator: this.validatePhone,
        trigger: "blur",
      },
    ],
    optional: [
      { required: true, message: "请选择是否允许项目自选", trigger: "blur" },
    ],
    onlinePayment: [
      { required: true, message: "请选择支付模式", trigger: "blur" },
    ],
    "jkglTenantDetail.tenantDetailInfo": [
      { required: true, message: "请填写机构介绍", trigger: "blur" },
    ],
  };
  public formLabelWidth = "120px";
  public area = area;
  public IsEdit = false;
  public formData = {
    name: "",
    code: "",
    img: "",
    imgAd:"",
    status: "",
    nature: "",
    grade: "",
    province: "",
    city: "",
    district: "",
    address: "",
    optional: "",
    onlinePayment: "",
    jkglTenantDetail: {
      tenantDetailLat: "",
      tenantDetailLong: "",
      tenantDetailTelephone: "",
      tenantDetailJobTime: "",
      tenantDetailInfo: "",
      tenantDetailImage: "",
      tenantDetailSort: "",
      advertisementDescription:"",// 广告描述
      advertisementImage: "", //广告图片
      advertisementLink:"" // 跳转链接
    },
  };
  public file!: string;

  //点击返回按钮
  public goBack() {
    this.$emit("back");
    this.IsEdit = false;
  }
  //手机号校验
  public validatePhone(
    rule: any,
    value: string | any[],
    callback: (arg0?: Error | undefined) => void
  ) {
    if ( value === "") {
      // eslint-disable-next-line callback-return
      callback(new Error("请输入联系方式!"));
    }
    // } else if (!/^1[3456789]d{9}$/.test(value as string)) {
    //   // eslint-disable-next-line callback-return
    //   callback(new Error('手机号格式不正确'));
    // }
    else {
      // eslint-disable-next-line callback-return
      callback();
    }
  }
  public async getTenant(row: { id: string }) {
    const res = await getNodeData(row.id);
    if (res.success) {
      this.formData = res.data;
    }
    this.IsEdit = true;
  }
  //赋值给表单img字段
  public async handleAvatarSuccess(res: { file: File }) {
    console.log("res照片 :>> ", res);
    const imgs = new FormData();
    imgs.append("file", res.file);
    const file = await uploadImg(imgs);
    this.file = file.data;
    this.formData.img = file.data;
    this.formData.jkglTenantDetail.tenantDetailImage = file.data;
  }
  //赋值给表单img字段
  public async handleAdSuccess(res: { file: File }) {
    console.log("res照片 :>> ", res);
    const imgs = new FormData();
    imgs.append("file", res.file);
    const file = await uploadImg(imgs);
    this.file = file.data;
    this.formData.imgAd = file.data;
    this.formData.jkglTenantDetail.advertisementImage = file.data;
  }
  //限制上传格式和大小
  public beforeAvatarUpload(file: { type: string; size: number }) {
    const isJPG = file.type === "image/jpeg";
    console.log("file.type :>> ", file.type);
    const isPNG = file.type === "image/png";
    const isPG = isJPG || isPNG;
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isPG) {
      this.$message.error("上传头像图片只能是 JPG/PNG格式!");
    }
    if (!isLt2M) {
      this.$message.error("上传头像图片大小不能超过 2MB!");
    }
    return isPG && isLt2M;
  }
  //选择省
  public selectProvinc() {
    this.formData.city = "";
    this.formData.district = "";
    this.$forceUpdate();
    console.log("this.formData.province :>> ", this.formData.province);
  }
  //地图选择确认
  public confirm(lnt: any, lat: any, address: any) {
    this.$set(this.formData.jkglTenantDetail, "tenantDetailLong", lnt);
    this.$set(this.formData.jkglTenantDetail, "tenantDetailLat", lat);
    this.$set(this.formData, "address", address);
  }
  //提交操作
  public handlerSubmit() {
    (this.$refs.formData as ElForm).validate(async (valid) => {
      if (valid) {
        this.$set(
          this.formData.jkglTenantDetail,
          "tenantDetailTelephone",
          String(this.formData.jkglTenantDetail.tenantDetailTelephone)
        );
        //更新操作
        const res = await addUpdateTenant(this.formData);
        if (res.success) {
          //额外的图片上传

          this.$emit("back", true);
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      } else {
        return false;
      }
    });
  }
}
